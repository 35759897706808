import React from "react"

import RCalendar from "react-calendar"

const bookings: [string, string][] = require("../../bookings.json")

// styles from ../styles/calendar.css need to be globally imported

export default function Calendar() {
  const toISODate = (date: Date) =>
    `${date.getFullYear()}-\
${("0" + (date.getMonth() + 1)).slice(-2)}-\
${("0" + date.getDate()).slice(-2)}`

  const isBooked = (date: Date) => {
    const isoDate = toISODate(date)
    // all-day bookings on google calendar end on the day after, e.g. a single all-day event for today starts today and ends tomorrow
    return !!bookings.find(
      booking => isoDate >= booking[0] && isoDate < booking[1]
    )
  }

  const tileClassName = ({ date, view }) => {
    // Add class to tiles in month view only
    if (view === "month") {
      if (isBooked(date)) {
        return "react-calendar--booked"
      }
    }
  }
  return (
    <RCalendar
      minDetail={"year"}
      showFixedNumberOfWeeks={true}
      value={new Date(2010, 1, 1)} // don't let dates be selected - this isn't interactive booking
      defaultActiveStartDate={new Date()} // start at today
      tileClassName={tileClassName} // highlight booked days
    />
  )
}
