import React from "react"

import Calendar from "../components/calendar"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Contact() {
  return (
    <Layout>
      <SEO
        title="Contact"
        description="Contact, bookings, reservations, and availability"
      />
      <h1>Availability</h1>
      <Calendar />
    </Layout>
  )
}
